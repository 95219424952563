$(document).ready(function () {
  //Text with image and other home page
  $(".atc-block-slider").slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  //Home page auto slider
  $("#featured-collection-slider").slick({
    infinite: true,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 0,
    cssEase: "linear",
    swipeToSlide: true,
    //centerMode: true,
    focusOnSelect: true,
    pauseOnHover: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    prevArrow:
      '<span class="arrang_arrows"><button type="button" class="slick-prev pull-left" ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M15.293 3.293 6.586 12l8.707 8.707 1.414-1.414L9.414 12l7.293-7.293-1.414-1.414z"></path></svg></button></span>',
    nextArrow:
      '<span class="arrang_arrows"><button type="button" class="slick-next pull-right" ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z"></path></svg></button></span>',

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $(".collection_menu_slider").slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    dots: false,
    arrows: true,
    prevArrow:
      '<span class="left_arrows"><button type="button" class="slick-prev pull-left" ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M15.293 3.293 6.586 12l8.707 8.707 1.414-1.414L9.414 12l7.293-7.293-1.414-1.414z"></path></svg></button></span>',
    nextArrow:
      '<span class="right_arrows"><button type="button" class="slick-next pull-right" ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z"></path></svg></button></span>',

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  });

  // Product page custom TAB
  var $tabsUl = $(".tabs");
  var $tabsContentUl = $(".tabs-content");

  // Get the number of li elements in the first ul
  var tabsCount = $tabsUl.find("li").length;

  // Iterate over the li elements and create combinations
  for (var i = 0; i < tabsCount; i++) {
    // Create a div to hold the current combination
    var $div = $('<div class="product_accordion_item">');

    // Get the li elements from both ul lists at the current index
    var $tabLi = $tabsUl.find("li:eq(" + i + ")");
    var $tabContentLi = $tabsContentUl.find("li:eq(" + i + ")");

    // Add "active" class to the first custom-accordion-header only
    var headerClass =
      i === 0 ? "custom-accordion-header active" : "custom-accordion-header";

    // Append the HTML content of the li elements to the div
    var accordian_html =
      '<button type="button" class="' +
      headerClass +
      '">' +
      $tabLi.html() +
      '<span class="icon">';
    accordian_html +=
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" height="20px" width="20px" version="1.1" id="Layer_1" viewBox="0 0 511.787 511.787" xml:space="preserve"><g><g>';
    accordian_html +=
      '<path d="M508.667,125.707c-4.16-4.16-10.88-4.16-15.04,0L255.76,363.573L18,125.707c-4.267-4.053-10.987-3.947-15.04,0.213    c-3.947,4.16-3.947,10.667,0,14.827L248.293,386.08c4.16,4.16,10.88,4.16,15.04,0l245.333-245.333    C512.827,136.693,512.827,129.867,508.667,125.707z"/>';
    accordian_html += "</g></g></svg></span>";
    accordian_html += '<span class="icon_active">';
    accordian_html +=
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xml:space="preserve"><defs></defs>';
    accordian_html +=
      '<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >';
    accordian_html +=
      '<path d="M 88.616 46.384 H 1.583 c -0.82 0 -1.484 -0.664 -1.484 -1.483 s 0.664 -1.484 1.484 -1.484 h 87.033 c 0.819 0 1.484 0.664 1.484 1.484 S 89.435 46.384 88.616 46.384 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />';
    accordian_html += "</g></svg>";

    accordian_html +=
      '</span></button><div class="accordion-content" >' +
      $tabContentLi.html() +
      "</div>";

    $div.append(accordian_html);

    // Append the div to the body or any other container you want
    $(".custom_product_description").append($div);
  }

  $("body").on("click", ".custom-accordion-header", function () {
    $(this).next().slideToggle();
    $(this).toggleClass("active");
  });

  // Scroll to CTA
  var url = window.location.href;
  var fragment = url.split("?")[1];
  console.log(fragment + "fragment");

  // if (url.includes('pages/our-values')) {
  if (fragment) {
    jQuery("html, body").animate(
      {
        scrollTop: jQuery("#" + fragment).offset().top,
      },
      1000
    );
  }

  // }
});


  $(document).ready(function(){
  var windowSize = $(window).width();
//   if (windowSize > 798) {
  
    var first_active = $('.swatch input:checked').next().attr('data-value').trim();
    //alert(first_active);
    //$('.main_product_thumbnail [data-title="'+first_active+'"]').addClass('active_variants');
   
  $( ".main_product_thumbnail" ).each(function() {
      var data_title = $(this).attr('data-title').trim();
  if( first_active === data_title){
    $(this).addClass('active_variants');
  }

    });
  
//   }
//   if (windowSize > 798) {
    $(document).on("click",".swatch-element",function(){

      $('.main_product_thumbnail.active_variants').removeClass('active_variants')
      var data_title = $(this).attr('data-value').trim();
     // $('.main_product_thumbnail [data-title="'+data_title+'"] ').addClass('active_variants');
     
  $( ".main_product_thumbnail" ).each(function() {
      var first_active = $(this).attr('data-title').trim();

  if( first_active === data_title){
    $(this).addClass('active_variants');
  }
    });
 
    });
  
//   }
//   else{
//         $(document).on("click",".swatch-element",function(){

//       $('.main_product_thumbnail').removeClass('is-nav-selected');
//       var data_title = $(this).attr('data-value');
//       $('.product-gallery__thumbnail[data-title="'+data_title+'"]').addClass('is-nav-selected');
//       console.log(data_title);

//     });
  
//   }
});
